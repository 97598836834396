<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getTaxInvoiceReport"></select-shop>

    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
          <h2 class="col-md-10 col-sm-9 col-8 font-weight-normal d-flex  align-items-center">
            <span id="newpaymentTitle">{{ $t('report.txtTaxSummary') }}</span>
          </h2>
            <div v-if="isExport" class="col-md-2 col-4 col-sm-3 text-right text-success">
              <CButton id="newpaymentExportButton" v-on:click="exportTaxInvoiceReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <DataTable style="white-space:nowrap" :items="items" :fields="fields" hover border :loading="loading">
              </DataTable>
            </CCol>
          </CRow>

          <pagination :meta_data="meta_data" v-on:next="getTaxInvoiceReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _style: 'width:10%',
          _classes: 'text-left font-weight-normal text-dark'
        },
        {
          key: 'taxReceipt',
          label: this.$i18n.t('taxInvoiceReceipt'),
          _classes: 'text-left font-weight-normal text-dark',
          _style: 'width:15%',
        },
        {
          key: 'receiptNumber',
          label: this.$i18n.t('receiptNumber'),
          _classes: 'text-left font-weight-normal text-dark',
          _style: 'width:15%',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('beforeDiscount'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'netAmountVATTotal',
          label: this.$i18n.t('netAmountVATTotal'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('tax'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'netServiceCharge',
          label: this.$i18n.t('serviceCharge'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'roundingValue',
          label: this.$i18n.t('roundingValue'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
        {
          key: 'netTotal',
          label: this.$i18n.t('total'),
          _classes: 'text-right text-dark font-weight-normal text-dark',
          _style: 'width:10%',
        },
      ]
    },

    items() {
      let data = this.data || []
      let detail = []

      for (let i = 0; i < data.length; i++) {
        const item = data[i]

        detail.push({
          date: moment(String(data[i].updated_at)).format('DD/MM/YYYY HH:mm:ss'),
          receiptNumber: item.receiptNumber,
          grandTotal: item.netAmount.toFixed(2),
          discountAmount: item.discountAmount.toFixed(2),
          vatAmount: item.vatAmount.toFixed(2),
          netServiceCharge: item.netAmountIncServiceCharge.toFixed(2),
          roundingValue : item.roundingValue.toFixed(2),
          netTotal: item.grandTotal.toFixed(2),
          netAmountVATTotal : item.netAmountVATTotal.toFixed(2),
          taxReceipt : item.taxInvoice
        })
      }
      return detail
    },
  },


  created() {
    this.getTaxInvoiceReport()
  },
  methods: {
    getTaxInvoiceReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/receipt/v1.0/taxinvoice/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportTaxInvoiceReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/taxinvoice/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานบิลที่ออกใบกำกับภาษี.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../src/assets/styles/sortCss.css"></style>

<style>
  .badge-xs {
    font-size: 0.50em; /* หรือปรับขนาดตามต้องการ */
    
  }
</style>